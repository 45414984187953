import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Navbar } from "flowbite-react";
import navLogo from "../../assets/navLogo.png";
import { useNavigate } from "react-router-dom";

export function Header({ user, tabs }) {
  const location = useLocation();
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();

  return (
    <>
    {console.log(location.pathname)}
      <Navbar fluid style={{ backgroundColor: "#2d3640" }}>
        <Navbar.Brand as={Link} href="https://hirein.org">
          <img
            src={navLogo}
            className="mr-4 h-12 sm:h-12"
            alt="Flowbite React Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          {tabs.map((tab, index) => (
            <Link key={index} to={tab.link}>
              <Button
                size="lg"
                variant="link"
                color="blue"
                style={{ marginRight: "10px" }}
                sx={{
                  color: location.pathname === tab.link ? "#02af74" : "#ffffff",
                  ":hover": {
                    backgroundColor: "#243d3f",
                  },
                }}
              >
                {tab.tabName}
              </Button>
            </Link>
          ))}
          
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
