import { useState, useEffect } from "react";
import {
  Text,
  Grid,
  Button,
  Center,
  Title,
  Skeleton,
  Container,
  createStyles,
  Avatar,
  Indicator,
} from "@mantine/core";
import { BiChevronRight } from "react-icons/bi";
import useFetchHook from "../../useFetchHook/useFetchHook.js";
import { Link } from 'react-router-dom';
import ParticleComponent from "../../components/ParticlesComponent/ParticlesComponent";
import { useCallback } from "react";
import Particles from "react-tsparticles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.


const useStyles = createStyles((theme) => ({
  CategorIcon: {
    width: "7rem",
    //height: "4rem",

    // Simplify media query writing with theme functions
    [theme.fn.smallerThan("md")]: {
      width: "4rem",
      // height: "4rem",
    },

    [theme.fn.smallerThan("sm")]: {
      width: "4rem",
      // height: "4rem",
    },

    [`@media (max-width: ${"30em"})`]: {
      width: "2rem",
      // height: "2.7rem",
    },
  },

  card: {
    height: "18vh",
    width: "18vh",

    // Simplify media query writing with theme functions
    [theme.fn.smallerThan("md")]: {
      height: "18vh",
      width: "18vh",
    },

    [theme.fn.smallerThan("sm")]: {
      height: "17vh",
      width: "17vh",
    },

    [`@media (max-width: ${"30em"})`]: {
      height: "15vh",
      width: "15vh",
    },
  },

  text: {
    color: "#ffffff",
    fontSize: "1rem",
    [`@media (max-width: ${"30em"})`]: {
      fontSize: "0.9rem",
    },
  },
}));

function CategoriesSection() {
  const particlesInit = useCallback(async engine => {
    await loadSlim(engine);
}, []);

const particlesLoaded = useCallback(async container => {
    console.log(container);
}, []);
  const apiUrl = localStorage.getItem("apiUrl");
  const { classes } = useStyles();
  const { data, loading, error, refetchData } =
    useFetchHook(`api/show/government`);
  const [categoriesData, setCategoriesData] = useState([]);
  useEffect(() => {
    if (!loading && data.length !== 0) {
      setCategoriesData(data);
    }
  }, [data]);
 const gov=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]
  return (
    <div style={{ position: "relative", background: "#283039", padding: "40px" }}>
       <Particles 
              id="tsparticles" 
              init={particlesInit} 
              loaded={particlesLoaded} 
              style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
              options={{ 
                particles: { 
                  number: { 
                    value: 200, 
                    density: { 
                      enable: false, 
                      value_area: 800, 
                    }, 
                  }, 
                  color: { 
                    value: "#02af74", 
                  }, 
                  shape: { 
                    type: "circle", 
                    stroke: { 
                      width: 0, 
                      color: "#000000", 
                    }, 
                    polygon: { 
                      nb_sides: 5, 
                    }, 
                    image: { 
                      src: "img/github.svg", 
                      width: 100, 
                      height: 100, 
                    }, 
                  }, 
                  opacity: { 
                    value: 0.5, 
                    random: true, 
                    anim: { 
                      enable: false, 
                      speed: 1, 
                      opacity_min: 0.1, 
                      sync: false, 
                    }, 
                  }, 
                  size: { 
                    value: 5, 
                    random: true, 
                    anim: { 
                      enable: false, 
                      speed: 40, 
                      size_min: 0.1, 
                      sync: false, 
                    }, 
                  }, 
                  line_linked: { 
                    enable: false, 
                    distance: 500, 
                    color: "#ffffff", 
                    opacity: 0.4, 
                    width: 2, 
                  }, 
                  move: { 
                    enable: true, 
                    speed: 1, 
                    direction: "bottom", 
                    random: false, 
                    straight: false, 
                    out_mode: "out", 
                    bounce: false, 
                    attract: { 
                      enable: true, 
                      rotateX: 600, 
                      rotateY: 1200, 
                    }, 
                  }, 
                }, 
                interactivity: { 
                  detect_on: "canvas", 
                  events: { 
                    onhover: { 
                      enable: false, 
                      mode: "bubble", 
                    }, 
                    
                    onclick: { 
                      enable: false, 
                      mode: "repulse", 
                    }, 
                    resize: true, 
                  }, 
                  modes: { 
                    grab: { 
                      distance: 400, 
                      line_linked: { 
                        opacity: 0.5, 
                      }, 
                    }, 
                    bubble: { 
                      distance: 400, 
                      size: 4, 
                      duration: 0.3, 
                      opacity: 1, 
                      speed: 3, 
                    }, 
                    repulse: { 
                      distance: 200, 
                      duration: 0.4, 
                    }, 
                    push: { 
                      particles_nb: 4, 
                    }, 
                    remove: { 
                      particles_nb: 2, 
                    }, 
                  }, 
                }, 
                retina_detect: true,


                fullScreen: { zIndex: 0,enable: false }, 
              }} 
            /> 
      <Container >
      <Title mb="lg" align="center" style={{ color: "#ffffff", fontSize: "3.1rem" }} order={3}>
              Jobs By Governorate
            </Title>
        <Grid gutter="lg" align="center">

          {loading?(
             gov.map((category) => (
              <Grid.Col xs={6} sm={4} md={2} lg={2}>
                   <Indicator
                  position="top-start"
                  color="#445c52"
                  inline
                  label="0"
                  size={26}
                >
                  <div
                    id={category}
                   
                    style={{
                      border: "1px solid #445c52",
                      backgroundColor: "#283039",
                      borderRadius: "10px",
                      padding:"10px",
                    }}
                  >
                   
                       <Center>
                       <Skeleton height={70} width={100}   mb="xl" />
                    </Center>
                   
                    <Skeleton height={8} mt={6} width="100%" radius="xl" />
                  </div>
                </Indicator>
              </Grid.Col>
            ))
          ):
          (
            categoriesData.map((category, index) => (
              <Grid.Col xs={6} sm={4} md={2} lg={2}>
                <Center>
                  <Link to={`/jobs/1?government=${category.value}&category=1`}>
                   <Indicator
                  position="top-start"
                  color="#445c52"
                  inline
                  label={category.job_count}
                  size={26}
                >
                  <div
                    id={index}
                   
                    style={{
                      border: "1px solid #445c52",
                      backgroundColor: "#283039",
                      borderRadius: "10px",
                      padding:"10px",
                    }}
                  >
                   
                       <Center>
                      <Avatar
                        size={"xl"}
                        alt="gov image"
                        className={classes.CategorIcon}
                        src={`${apiUrl}images/government/${category.image}`}
                      />
                    </Center>
                   
                    <Text align="center" weight={500} className={classes.text}>
                      {category.label}
                    </Text>
                  </div>
                </Indicator>
                </Link>
                </Center>
                
               
              </Grid.Col>
            ))
          )

          }

        </Grid>
        <Center mt="lg">
          <Link to={`/jobs/1/1/1`}>
          <Button
            size={"lg"}
            rightIcon={<BiChevronRight />}
            style={{ backgroundColor: "#02af74" }}
          >
            Browse All Jobs
          </Button>
          </Link>
          
        </Center>
      </Container>
    </div>
  );
}

export default CategoriesSection;
