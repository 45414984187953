import {
  Skeleton,
  Avatar,
  Title,
  Group,
  Badge,
  Divider,
  Card,
  Button,
} from "@mantine/core";
import { Link } from "react-router-dom";
import {
  BiLocationPlus,
  BiChevronsRight,
} from "react-icons/bi";


function BidsPageLoading() {
  return (
    <Card
      style={{
        background: "#283039",
        border: "2px solid #445c52",
        borderRadius: "15px",
        marginTop: "20px",
      }}
    >
      <Group position="apart">
        
        <Skeleton height={19} mt={6} width="10%" radius="xl" />
        

        
        <Skeleton height={19} mt={6} width="10%" radius="xl" />
       
      </Group>

      <Divider color="gray" my="sm" variant="dashed" />
      <Group position="apart" spacing="xl">
        <Group>
        <Skeleton height={50}  width={50} radius="md" />

            <Skeleton height={19}  width={70} radius="xl" />
          
        </Group>

        
          <div style={{ display: "flex", alignItems: "center" }}>
          <Skeleton height={19}  width={70} radius="xl" />
            <BiLocationPlus
              size="1.5rem"
              color="#02af74"
              style={{ marginLeft: "0.5rem" }}
            />
          </div>
        
        <Group>
          <Badge
            style={{ background: "#01462e", color: "#ffffff" }}
            radius="sm"
          >
            <Skeleton height={8} mt={6} width="30%" radius="xl" />
          </Badge>
          <Badge
            style={{ background: "#635221", color: "#ffffff" }}
            radius="sm"
          >
            <Skeleton height={8} mt={6} width="30%" radius="xl" />
          </Badge>
        </Group>
        
          <Button
            size={"lg"}
            rightIcon={<BiChevronsRight />}
            style={{ backgroundColor: "#02af74" }}
          >
            More Details
          </Button>
        
      </Group>
    </Card>
  );
}

export default BidsPageLoading;
