import { useState } from "react";
import {
  Title,
  Card,
  Button,
  Grid,
  TypographyStylesProvider,
  Divider,
  SimpleGrid,
  Image,
  Center,
} from "@mantine/core";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import useFetchHook from "../../useFetchHook/useFetchHook";

import testLogo from "../../assets/testLogo.png";

function BidDetailsPage() {

  let { id } = useParams();
  const { data, loading, error, refetchData } = useFetchHook(
    `api/show/bids/details/${id}`
  );
  const apiUrl = localStorage.getItem("apiUrl");
  const [bidDetailsData, setBidDetailsData] = useState([]);
  useEffect(() => {
    if (!loading) {
      setBidDetailsData(data);
    }
  }, [loading, data]);

  const circleStyle = {
    backgroundColor: "#31795a",
    color: "white",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
    fontSize: "20px",
  };
  return (
    <div>
      <div
        style={{
          backgroundColor: "#243d3f",
          padding: "40px",
          borderRadius: "0 0 40% 40%",
        }}
      >
        <Title
          align="center"
          style={{ color: "#ffffff", fontSize: "3.5rem" }}
          order={1}
        >
          bid <span style={{ color: "#02af74" }}>Details</span>
        </Title>
      </div>

      <div style={{ padding: "20px" }}>
        <Grid gutter="xl" justify="center">
          <Grid.Col orderXs={2} orderSm={2} orderLg={1} orderXl={1}  xs={12} sm={12} md={12} lg={7}>
            <div style={{ margin: "20px" }}>
              <Title style={{ color: "#7f7f7f" }} order={5}>
                {bidDetailsData.start_publishing}{" "}
                <samp style={{ color: "#000" }}>
                  {bidDetailsData.organization_name}
                </samp>
              </Title>
              <Title style={{ fontSize: "2.5rem" }} order={1}>
                {bidDetailsData.bids_title}
              </Title>
            </div>

            <Card
              style={{
                borderRadius: "10px",
                border: "1px solid #000000",
                margin: "15px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={circleStyle}>1</div>
                <h2 style={{ textAlign: "center" }}>Description</h2>
              </div>
              <TypographyStylesProvider>
                <div
                  dangerouslySetInnerHTML={{
                    __html: bidDetailsData.bids_description,
                  }}
                />
              </TypographyStylesProvider>
            </Card>
          </Grid.Col>
          <Grid.Col orderXs={1} orderSm={1}  xs={12} orderLg={2} orderXl={2} sm={12} md={3} lg={3}>
            <div
              style={{
                padding: "20px",
                backgroundColor: "#eff6f3",
                borderRadius: "10px",
              }}
            >
              <div>
                <Center>
                  <Image
                    style={{
                      width: "15vh",
                      height: "auto",
                      marginBottom: "20px",
                    }}
                    fit="contain"
                    src={bidDetailsData.organization_img}
                  />
                </Center>

                <Title align="center" mb={20} order={1}>
                  {bidDetailsData.organization_name}
                </Title>
                <Divider></Divider>
              </div>

              <SimpleGrid cols={2} spacing="xl" verticalSpacing="xl">
                <div
                  style={{
                    color: "#768880",
                    fontWeight: "bold",
                    fontSize: "larger",
                  }}
                >
                  Publish Date <br />
                  <span style={{ color: "#000" }}>
                    {bidDetailsData.start_publishing}
                  </span>
                </div>

                <div
                  style={{
                    color: "#768880",
                    fontWeight: "bold",
                    fontSize: "larger",
                  }}
                >
                  Last Date <br />
                  <span style={{ color: "#000" }}>
                    {bidDetailsData.stop_publishing}
                  </span>
                </div>

                
              </SimpleGrid>
              <Divider></Divider>
              <Center>
                  <a
                    href={apiUrl+"api/download-files/"+id}
                    rel="stylesheet"
                  >
                    <Button
                      size="lg"
                      style={{ backgroundColor: "#02af74", marginTop: "20px" }}
                    >
                      Download the attachments
                    </Button>
                  </a>
                </Center>
            </div>
          </Grid.Col>
        </Grid>
      </div>
    </div>
  );
}

export default BidDetailsPage;
