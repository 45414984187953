import React from "react";
//import pageNotFound from "../../assets/pageNotFound.json";
import Lottie from "lottie-react";
import { Button, Title } from "@mantine/core";
import { Link } from "react-router-dom";

function Page404Error() {
  // const options = {
  //   animationData: pageNotFound,
  //   loop: true,
  // };

  return (
    <div
      style={{
        background: "#283039",
        height: "93vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Lottie
        //animationData={pageNotFound}
        loop={true}
        style={{ width: "60vh", height: "60vh" }}
      />
      <Title
        align="center"
        style={{ color: "#ffffff", fontSize: "3rem", marginBottom: "10px" }}
      >
        Sorry, page not found
      </Title>

      <Link to={`/`}>
        <Button size={"lg"} style={{ backgroundColor: "#02af74" }}>
          Back to Home Page
        </Button>
      </Link>
    </div>
  );
}

export default Page404Error;
