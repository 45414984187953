import HeroSection from "../../components/HeroSection/HeroSection"
import CategoriesSection from "../../components/CategoriesSection/CategoriesSection"
import LatestPartnersSection from "../../components/LatestPartnersSection/LatestPartnersSection"
import ConntactUsSection from '../../components/ConntactUsSection/ConntactUsSection';
function HomePage() {
  return (
    <div>
      <HeroSection />
      <CategoriesSection />
      {/* <LatestPartnersSection /> */}
      <ConntactUsSection />
    </div>
  );
}

export default HomePage;


