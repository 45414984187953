import { useState, useEffect } from "react";
import {
  Avatar,
  Title,
  Group,
  Select,
  Badge,
  Divider,
  Grid,
  Button,
  Pagination,
} from "@mantine/core";
import { Link, useParams, useNavigate,useLocation } from "react-router-dom";
import { BiLocationPlus, BiChevronsRight, BiSearchAlt2 } from "react-icons/bi";
import { getData } from "../../api/ApiFunctions";
import useFetchHook from "../../useFetchHook/useFetchHook.js";
import BidsPageLoading from "../../components/LoadingComponents/BidsPageLoading";
import { Helmet } from "react-helmet-async";

function JobsPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");
  const government = queryParams.get("government");
  const navigate = useNavigate();
  const { id } = useParams();
  const [categoryValue, setCategoryValue] = useState(Number(category));
  const [categoryData, setCategoryData] = useState([]);
  const [governmentValue, setGovernmentValue] = useState(Number(government));
  const [governmentData, setGovernmentData] = useState([]);
  const getCategoresAndGovernmentData = async () => {
    const apiUrl = localStorage.getItem("apiUrl");
    const url = apiUrl + "api/show/category/government";
    const response = await getData(url);
    if (!response.error) {
      setCategoryData(response.data.category);
      setGovernmentData(
        response.data?.government || [{ lebel: "All", value: 1 }]
      );
    }
  };
  const [pageNumber, setPageNumber] = useState(1);
  const { data, loading, error, refetchData } = useFetchHook(
    `api/show/jobs/${Number(government)}&${Number(category)}?page=${id}`
  );
  const [JobsData, setJobsData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!loading && data.length !== 0) {
      setJobsData(data?.jobs?.data || []);
      getCategoresAndGovernmentData();
    }
  }, [data]);
  return (
    <div style={{ background: "#283039", padding: "40px", minHeight: "92vh" }}>
      <Helmet>
        <title>HireIn Jobs</title>
        <link rel="canonical" href="/Jobs/1/1/1" />
        <meta
          name="description"
          content="Browse the latest job listings in Iraq on HireIn. Find your dream job in Iraq's top organizations and NGOs."
        />
        <meta
          property="og:description"
          content="Browse the latest job listings in Iraq on HireIn. Find your dream job in Iraq's top organizations and NGOs."
        />
        <meta property="og:title" content="HireIn Jobs" />
      </Helmet>
    <div>
       <Group mt="lg">
        <Title style={{ color: "#ffffff", fontSize: "3.5rem" }} order={1}>
          filter to find <span style={{ color: "#02af74" }}>Jobs</span>
        </Title>

        <Select
          label="government"
          labelProps={{ style: { color: "#ffffff" } }}
          data={governmentData}
          value={governmentValue}
          onChange={(event) => setGovernmentValue(event)}
        />
        <Select
          label="category"
          labelProps={{ style: { color: "#ffffff" } }}
          data={categoryData}
          value={categoryValue}
          onChange={(event) => setCategoryValue(event)}
        />
        <Button
          size={"lg"}
          leftIcon={<BiSearchAlt2 />}
          style={{ backgroundColor: "#02af74" }}
          onClick={() => {
            navigate(`/jobs/1?government=${governmentValue}&category=${categoryValue}`);
          }}
        >
          Search
        </Button>
      </Group>
      <Divider  variant="dotted" />
    </div>
     

      {loading || error ? (
        <div>
          <BidsPageLoading />
          <BidsPageLoading />
          <BidsPageLoading />
          <BidsPageLoading />
          <BidsPageLoading />
        </div>
      ) : (data?.jobs?.data || []).length === 0 ? (
        <div>
        <Title
          align="center"
          style={{ color: "#ffffff", fontSize: "3.5rem", marginTop: "20vh" }}
          order={1}
        >
          No records found 😔 
          {/* <span style={{ color: "#02af74" }}>HireIn</span> Bids and Tenders */}
        </Title>
      </div>
      ) : (
        JobsData.map((item, index) => (
          <div
            id={index}
            style={{
              background: "#283039",
              border: "2px solid #445c52",
              borderRadius: "15px",

              marginTop: "20px",
            }}
          >
            <div style={{ padding: "10px" }}>
              <Group position="apart">
                <Title style={{ color: "#ffffff" }} order={2}>
                  {item.job_title}
                </Title>

                <Title style={{ color: "#02af74" }} order={2}>
                  {item.ago_time}
                </Title>
              </Group>

              <Divider color="gray" my="sm" variant="dashed" />

              <Grid>
                <Grid.Col xs={12} sm={6} md={6} lg={6}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar size="lg" radius="md" src={item.organization_img} />

                    <div style={{ marginLeft: "15px", marginRight: "20px" }}>
                      <Title style={{ color: "#02af74" }} order={3}>
                        {item.organization_name}
                      </Title>
                      <Title style={{ color: "#adb5bd" }} order={4}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          Iraq, {item.government_name}
                          <BiLocationPlus
                            size="1.5rem"
                            color="#02af74"
                            style={{ marginLeft: "0.5rem" }}
                          />
                        </div>
                      </Title>
                    </div>
                  </div>
                </Grid.Col>

                <Grid.Col xs={6} sm={3} md={3} lg={3}>
                  <Group>
                    <Badge
                      style={{ background: "#01462e", color: "#ffffff" }}
                      radius="sm"
                    >
                      {item.working_hours}
                    </Badge>
                    <Badge
                      style={{ background: "#635221", color: "#ffffff" }}
                      radius="sm"
                    >
                      number Of Jobs= {item.no_of_jobs}
                    </Badge>
                  </Group>
                </Grid.Col>

                <Grid.Col xs={6} sm={3} md={3} lg={3}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Link to={`/JobDetailsPage/${item.job_id}`}>
                      <Button
                        size={"lg"}
                        rightIcon={<BiChevronsRight />}
                        style={{ backgroundColor: "#02af74" }}
                      >
                        Apply
                      </Button>
                    </Link>
                  </div>
                </Grid.Col>
              </Grid>
            </div>
          </div>
        ))
      )}

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {data.last_page > 1 ? (
          <Pagination
            mt={12}
            defaultValue={Number(id)}
            total={data.last_page}
            color="teal"
            onChange={(e) => {
              window.scrollTo(0, 0);
              navigate(`/jobs/${e}?government=${governmentValue}&category=${categoryValue}`);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default JobsPage;
