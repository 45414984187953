import { useState } from "react";
import {
  Title,
  Card,
  Button,
  Grid,
  TypographyStylesProvider,
  Divider,
  SimpleGrid,
  Image,
  Center,
} from "@mantine/core";
import { useParams } from 'react-router-dom';
import { useEffect } from "react";
import  useFetchHook  from "../../useFetchHook/useFetchHook";

import testLogo from "../../assets/testLogo.png";

function JobDetailsPage() {
  let { id } = useParams();
  const { data, loading, error, refetchData } = useFetchHook(
    `api/show/jobs/${id}`,
  );
  const [JobDetailsData,setjobDetailsData]=useState([]);
  useEffect(() => {
    if (!loading) {
      setjobDetailsData(data);
    }


  }, [loading, data]);

  const circleStyle = {
    backgroundColor: "#31795a",
    color: "white",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
    fontSize: "20px",
  };
  return (
    
    <div>
      <div
        style={{
          backgroundColor: "#243d3f",
          padding: "40px",
          borderRadius: "0 0 40% 40%",
        }}
      >
        <Title
          align="center"
          style={{ color: "#ffffff", fontSize: "3.5rem" }}
          order={1}
        >
          Job <span style={{ color: "#02af74" }}>Details</span>
        </Title>
      </div>

      <div style={{ padding: "20px" }}>

      
        <Grid gutter="xl" justify="center">
          <Grid.Col orderXs={2} orderSm={2} orderLg={1} orderXl={1} xs={12} sm={12} md={12} lg={7}>
            <div style={{ margin: "20px" }}>
              <Title style={{ color: "#7f7f7f" }} order={5}>
                {JobDetailsData.start_publishing} <samp style={{ color: "#000" }}>{JobDetailsData.organization_name}</samp>
              </Title>
              <Title style={{ fontSize: "2.5rem" }} order={1}>
              {JobDetailsData.job_title}
              </Title>
            </div>

            <Card
              style={{
                borderRadius: "10px",
                border: "1px solid #000000",
                margin: "15px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={circleStyle}>1</div>
                <h2 style={{ textAlign: "center" }}>Overview</h2>
              </div>
              <TypographyStylesProvider>
                <div dangerouslySetInnerHTML={{ __html: JobDetailsData.job_overview }} />
              </TypographyStylesProvider>
            </Card>

            <Card
              style={{
                borderRadius: "10px",
                border: "1px solid #000000",
                margin: "15px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={circleStyle}>2</div>
                <h2 style={{ textAlign: "center" }}>Job Description</h2>
              </div>
              <TypographyStylesProvider>
                <div dangerouslySetInnerHTML={{ __html: JobDetailsData.job_descriptions }} />
              </TypographyStylesProvider>
            </Card>

            <Card
              style={{
                borderRadius: "10px",
                border: "1px solid #000000",
                margin: "15px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={circleStyle}>3</div>
                <h2 style={{ textAlign: "center" }}>Responsibilities</h2>
              </div>
              <TypographyStylesProvider>
                <div dangerouslySetInnerHTML={{ __html: JobDetailsData.job_responsibilities }} />
              </TypographyStylesProvider>
            </Card>

            <Card
              style={{
                borderRadius: "10px",
                border: "1px solid #000000",
                margin: "15px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={circleStyle}>4</div>
                <h2 style={{ textAlign: "center" }}>Required Skills</h2>
              </div>
              <TypographyStylesProvider>
                <div dangerouslySetInnerHTML={{ __html: JobDetailsData.job_required_skills }} />
              </TypographyStylesProvider>
            </Card>

            <Card
              style={{
                borderRadius: "10px",
                border: "1px solid #000000",
                margin: "15px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={circleStyle}>5</div>
                <h2 style={{ textAlign: "center" }}>How to Apply</h2>
              </div>
              <TypographyStylesProvider>
                <div dangerouslySetInnerHTML={{ __html: JobDetailsData.job_how_to_apply }} />
              </TypographyStylesProvider>
            </Card>
          </Grid.Col>

          <Grid.Col orderXs={1} orderSm={1} orderLg={2} orderXl={2} xs={12} sm={12} md={3} lg={3}>
            <div
              style={{
                padding: "20px",
                backgroundColor: "#eff6f3",
                borderRadius: "10px",
              }}
            >
              <div>
                 <Center>
             <Image
                style={{ width: '15vh', height: 'auto', marginBottom: '20px' }}
               fit="contain"
                src={JobDetailsData.organization_img}
              />
              
             </Center>
                 
                 <Title align="center" mb={20} order={1}>{JobDetailsData.organization_name}</Title>
                 <Divider></Divider>

              </div>
            
              <SimpleGrid cols={1} spacing="xl" verticalSpacing="xl">
                <div
                  style={{
                    color: "#768880",
                    fontWeight: "bold",
                  }}
                >
                  Salary: 
                  <span style={{ color: "#000" }}>{" "+JobDetailsData.salary }</span>
                </div>

                <div
                  style={{
                    color: "#768880",
                    fontWeight: "bold",
                  }}
                >
                  Expertise: 
                  <span style={{ color: "#000" }}>{" "+JobDetailsData.job_experience }</span>
                </div>

                <div
                  style={{
                    color: "#768880",
                    fontWeight: "bold",
                  }}
                >
                  Location: 
                  <span style={{ color: "#000" }}>{" "+JobDetailsData.government_name }</span>
                </div>

                <div
                  style={{
                    color: "#768880",
                    fontWeight: "bold",
                  }}
                >
                  Required Travel: 
                  <span style={{ color: "#000" }}>{" "+JobDetailsData.required_travel }</span>
                </div>

                <div
                  style={{
                    color: "#768880",
                    fontWeight: "bold",
                  }}
                >
                  Jobt Type: 
                  <span style={{ color: "#000" }}>{" "+JobDetailsData.job_type }</span>
                </div>

                <div
                  style={{
                    color: "#768880",
                    fontWeight: "bold",
                  }}
                >
                  nationality: 
                  <span style={{ color: "#000" }}>{" "+JobDetailsData.nationality }</span>
                </div>

                <div
                  style={{
                    color: "#768880",
                    fontWeight: "bold",
                  }}
                >
                  Number Of Jobs: 
                  <span style={{ color: "#000" }}>{" "+JobDetailsData.no_of_jobs }</span>
                </div>

                <div
                  style={{
                    color: "#768880",
                    fontWeight: "bold",
                  }}
                >
                  Publish Date: 
                  <span style={{ color: "#000" }}>{" "+JobDetailsData.start_publishing }</span>
                </div>

                <div
                  style={{
                    color: "#768880",
                    fontWeight: "bold",
                  }}
                >
                  Last Date: 
                  <span style={{ color: "#000" }}>{" "+JobDetailsData.stop_publishing }</span>
                </div>
              </SimpleGrid>

             
            </div>
          </Grid.Col>
        </Grid>
      </div>
    </div>
  );
}

export default JobDetailsPage;
