import {
  Grid,
  Image,
  Title,
  createStyles,
} from "@mantine/core";
import job1 from "../../assets/job1.svg";



const useStyles = createStyles((theme) => ({
  heroGrid: {
    marginLeft: "10vh",

    [theme.fn.smallerThan("md")]: {
      marginLeft: "0vh",
    },

    [theme.fn.smallerThan("sm")]: {
      marginLeft: "0vh"
    },
    [theme.fn.smallerThan("xs")]: {
      marginLeft: "0vh"
    },
  },

 

  imageContainer: {
    [`@media (max-width: ${"30em"})`]: {
      display: "none",
    },
  },


}));
function HeroSection() {
  const { classes } = useStyles();
  return (
    <div style={{ background: "#243d3f", padding: "40px" }}>
      
        <Grid gutter="lg" align="center">
          <Grid.Col xs={12} sm={6} md={6} lg={6} className={classes.heroGrid} >
            <Title style={{ color: "#ffffff", fontSize: "3.5rem" }} order={1}>
              Find your dream jobs <br />
              with <span style={{ color: "#02af74" }}>HireIn</span>
            </Title>
            <Title style={{ color: "#adb5bd" }} order={3}>
            Find job opportunities and tenders from NGOs and companies on HireIn.
            Connect job seekers with organizations looking to hire
            </Title>

           
          </Grid.Col>

          <Grid.Col className={classes.imageContainer} xs={12} sm={6} md={5} lg={4} style={{ textAlign: "center", margin: "auto" }}>
            <Image
              maw={220}
              mx="auto"
              radius="md"
              src={job1}
              alt="Random image"
            />
          </Grid.Col>
        </Grid>
     
    </div>
  );
}

export default HeroSection;
