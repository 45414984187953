import { notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import React from "react"; // Import React if not already imported
import axios from 'axios';

// Configure your API base URL and other Axios settings here
axios.defaults.baseURL = localStorage.getItem("apiUrl");


axios.interceptors.request.use((config) => {
  const access_token = localStorage.getItem("access_token");
  config.headers['Authorization'] = `Bearer ${access_token}`;
  return config;
}, (error) => {
  return Promise.reject(error);
});



 const refreshToken = async () => {
  try {
    const response = await axios.post("api/refresh");
    const data = response.data;
    // Handle the response data
    localStorage.setItem("access_token", data.access_token);
  } catch (error) {
    // Handle errors
    console.log("update tokin error  ",error);
  }
};



export const ddedeleteData = async (id, restUrl) => {
  try {
    const response = await axios.delete(restUrl + id);
    const data = response.data;

    refreshToken();
   
    // Handle the response data
    return { loading: false, data, error: false };
  } catch (error) {
    // Handle errors
    return { loading: false, data: false, error: error.message };
  }
};




export const deleteData = async (id, restUrl) => {
  notifications.show({
    id: "load-data",
    loading: true,
    color: "red",
    title: "جاري حذف القسم",
    autoClose: false,
    withCloseButton: false,
  });
  const apiUrl = localStorage.getItem("apiUrl");
  const url = apiUrl + restUrl + id;

  try {
    const response = await fetch(url, {
      method: "DELETE",
    });
    const data = await response.json();
    setTimeout(() => {
      notifications.update({
        id: "load-data",
        color: "teal",
        title: "تم حذف القسم",
        icon: React.createElement(IconCheck, { size: "1rem" }),
        autoClose: 2000,
      });
    }, 100);
    return { loading: false, data, error: false };
  } catch (error) {
    setTimeout(() => {
      notifications.update({
        id: "load-data",
        color: "red",
        title: "يوجد خلل لم يتم حذف القسم",
        autoClose: 3000,
      });
    }, 100);

    return { loading: false, data: false, error: error.message };
  }
};



export const creatData = async (postData, postUrl) => {
  const access_token = localStorage.getItem("access_token");
  try {
    const response = await fetch(postUrl, {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "trn-api-key": "123123",
        "content-type": "application/json",
        "Authorization":  `Bearer ${access_token}`,
      },
      body: JSON.stringify(postData),
    });
    const data = await response.json();
    console.log(data);
    setTimeout(() => {
      notifications.update({
        id: "load-data",
        color: "teal",
        title: "Done!",
        icon: React.createElement(IconCheck, { size: "1rem" }),
        autoClose: 2000,
      });
    }, 100);
    // You can return any relevant data here if needed
    return { loading: false, data, error: false };
  } catch (error) {
    console.error("Error:", error);
    setTimeout(() => {
      notifications.update({
        id: "load-data",
        color: "red",
        title: "could not send data",
        autoClose: 3000,
      });
    }, 100);
    // Return the error here
    return { loading: false, data: false, error: error.message };
  }
};






export const updateData = async (postData, postUrl) => {
  notifications.show({
    id: "load-data",
    loading: true,
    color: "red",
    title: "جاري رفع البيانات",
    autoClose: false,
    withCloseButton: false,
  });

  try {
    const response = await fetch(postUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });
    const data = await response.json();
    setTimeout(() => {
      notifications.update({
        id: "load-data",
        color: "teal",
        title: "تم رفع البيانات",
        icon: React.createElement(IconCheck, { size: "1rem" }),
        autoClose: 2000,
      });
    }, 100);
    // You can return any relevant data here if needed
    return { loading: false, data, error: false };
  } catch (error) {
    console.error("Error:", error);
    setTimeout(() => {
      notifications.update({
        id: "load-data",
        color: "red",
        title: "حدث خطأ أثناء رفع البيانات",
        autoClose: 3000,
      });
    }, 100);
    // Return the error here
    return { loading: false, data: false, error: error.message };
  }
};

export const sendFile = (ImageFile, name, itemPrice, sectionId) => {
  const apiUrl = localStorage.getItem("apiUrl");
  const url = apiUrl + "/api/create/item";
  const formData = new FormData();
  formData.append("image", ImageFile);
  formData.append("Name", name);
  formData.append("Price", itemPrice);
  formData.append("Section_Fk", sectionId);
  formData.append("Sequence", 1);
  formData.append("Note", "dsfsdfdsfsfd");
  fetch(url, {
    method: "POST",
    headers: {
      "access-control-allow-origin": "*",
      "trn-api-key": "123123",
    },
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      // Handle the response data
     
      document.getElementById("itemName").value = "";
      document.getElementById("itemPrice").value = "";
      // Update the sectionData state or perform any necessary actions
    })
    .catch((error) => {
      // Handle the error
      console.error("Error:", error);
    });
};



export const updateWithFile=async (postData, postUrl) => {
  notifications.show({
    id: "load-data",
    loading: true,
    color: "red",
    title: "جاري رفع البيانات",
    autoClose: false,
    withCloseButton: false,
  });

  try {
    const response = await fetch(postUrl, {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
       // 'Content-Length': '26720952', // Set the content length here

      },
      body: postData,
    });
    const data = await response.json();
    console.log(data);
    setTimeout(() => {
      notifications.update({
        id: "load-data",
        color: "teal",
        title: "تم رفع البيانات",
        icon: React.createElement(IconCheck, { size: "1rem" }),
        autoClose: 2000,
      });
    }, 100);
    // You can return any relevant data here if needed
    return { loading: false, data, error: false };
  } catch (error) {
    console.error("Error:", error);
    setTimeout(() => {
      notifications.update({
        id: "load-data",
        color: "red",
        title: "حدث خطأ أثناء رفع البيانات",
        autoClose: 3000,
      });
    }, 100);
    // Return the error here
    return { loading: false, data: false, error: error.message };
  }
};


export const getData = async (postUrl,postData) => {
  
  try {
    const response = await fetch(postUrl, {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });
    const data = await response.json();
    return { loading: false, data, error: false };
  } catch (error) {
    console.error("Error:", error);
    setTimeout(() => {
      notifications.update({
        id: "load-data",
        color: "red",
        title: "حدث خطأ أثناء رفع البيانات",
        autoClose: 3000,
      });
    }, 100);
    // Return the error here
    return { loading: false, data: false, error: error.message };
  }
};


export const logInCHeck = async (postUrl) => {
  notifications.show({
    id: "load-data",
    loading: true,
    color: "red",
    title: "جاري رفع البيانات",
    autoClose: false,
    withCloseButton: false,
  });
  const access_token = localStorage.getItem("access_token");
  try {
    const response = await fetch(postUrl, {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "trn-api-key": "123123",
        "content-type": "application/json",
        "Authorization":  `Bearer ${access_token}`,
      },
      body: JSON.stringify({}),
    });
    const data = await response.json();

    setTimeout(() => {
      notifications.update({
        id: "load-data",
        color: "teal",
        title: "تم رفع البيانات",
        icon: React.createElement(IconCheck, { size: "1rem" }),
        autoClose: 2000,
      });
    }, 100);
    // You can return any relevant data here if needed
    return { loading: false, data, error: false };
  } catch (error) {
    console.error("Error:", error);
    setTimeout(() => {
      notifications.update({
        id: "load-data",
        color: "red",
        title: "حدث خطأ أثناء رفع البيانات",
        autoClose: 3000,
      });
    }, 100);
    // Return the error here
    return { loading: false, data: false, error: error.message };
  }
};
















// // Add an Axios response interceptor to handle token refresh and error responses
// axios.interceptors.response.use((response) => {
//   console.log("this is the result from the api",response);
//   return response;
// }, async (error) => {
//   if (error.response.status === 401) {
//     // Handle token refresh logic here
//     // You can use your refresh token to obtain a new access token
//     // You should refresh the token and retry the original request
//     // Make sure you return the new request
//     // Example: await refreshToken(); and then axios(error.config);
//   }

//   // Handle other error cases here
//   return Promise.reject(error);
// });
