import { useState } from "react";
import {
  Grid,
  Image,
  Title,
  Button,
  Center,
  createStyles,
  TextInput,
} from "@mantine/core";
import conntactUs1 from "../../assets/conntactUs1.svg";
import { creatData } from "../../api/ApiFunctions";
import { useForm } from "@mantine/form";

const useStyles = createStyles((theme) => ({
  heroGrid: {
    marginLeft: "10vh",

    [theme.fn.smallerThan("md")]: {
      marginLeft: "0vh",
    },

    [theme.fn.smallerThan("sm")]: {
      marginLeft: "0vh",
    },
    [theme.fn.smallerThan("xs")]: {
      marginLeft: "0vh",
    },
  },

  imageContainer: {
    [`@media (max-width: ${"30em"})`]: {
      display: "none",
    },
  },
  customTextarea: {
    backgroundColor: "#243d3f !important",
    color: "#FFFFFF !important",
  },
}));
function ConntactUsSection() {
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  //const [message, setMessage] = useState("");
  const form = useForm({
    initialValues: {
      name: "",
      subject: "",
      email: "",
      content: "",
    },

    validate: {
      name: (value) => (value === "" ? "name is required" : null),
      subject: (value) => (value === "" ? "subject is required" : null),
      email: (value) => (value === "" ? "email is required" : null),
      content: (value) => (value === "" ? "message is required" : null),
    },
  });
  const handleSendMessage = async (messageData) => {
    const apiUrl = localStorage.getItem("apiUrl") + `api/create/message`;
    const postData = {
      ...messageData
    };
    setSendMessageLoading(true);
    const response = await creatData(postData, apiUrl);
    if (!response.error) {
      setSendMessageLoading(false);
      form.reset();
    } else {
      console.error("Error occurred during Job creation:", response.error);
    }
  };

  const { classes } = useStyles();
  return (
    <div style={{ background: "#243d3f", padding: "40px" }}>
      <Grid gutter="lg" align="center">
        <Grid.Col xs={12} sm={6} md={6} lg={6} className={classes.heroGrid}>
          <Title style={{ color: "#ffffff", fontSize: "3rem" }}order={3}>
            Contact Us
          </Title>
          <Title style={{ color: "#adb5bd" }} order={3}>
            you can sent us an email to be a partner on this email
            <span style={{ color: "#02af74" }}> support@hirein.org </span>
            or send us a message on the text era
          </Title>

          <div grow mt="lg">
            <form
              onSubmit={form.onSubmit((values) => {
                handleSendMessage(values);
              })}
            >
              <div style={{ width: "100%", marginTop: "12px" }}>
                <label style={{ color: "#FFFFFF" }} htmlFor="name">
                  Name
                </label>
                <input
                  id="name"
                  type="text"
                  style={{
                    backgroundColor: "#283039",
                    color: "#FFFFFF",
                    border: "none",
                    display: "flex",
                    resize: "none",
                    borderRadius: "8px",
                    width: "100%",
                    padding: "10px",
                  }}
                  {...form.getInputProps("name")}
                  withAsterisk
                />
              </div>

              <Center>
                <div
                  style={{
                    width: "100%",
                    marginRight: "10px",
                    marginTop: "12px",
                  }}
                >
                  <label style={{ color: "#FFFFFF" }} htmlFor="email">
                    Email
                  </label>
                  <input
                    id="email"
                    title=" Email "
                    type="text"
                    style={{
                      backgroundColor: "#283039",
                      color: "#FFFFFF",
                      border: "none",
                      display: "flex",
                      resize: "none",
                      borderRadius: "8px",
                      width: "100%",
                      padding: "10px",
                    }}
                    {...form.getInputProps("email")}
                    withAsterisk
                  />
                </div>
                <div style={{ width: "100%", marginTop: "12px" }}>
                  <label style={{ color: "#FFFFFF" }} htmlFor="subject">
                    Subject
                  </label>
                  <input
                    id="subject"
                    label=" Subject "
                    type="text"
                    style={{
                      backgroundColor: "#283039",
                      color: "#FFFFFF",
                      border: "none",
                      display: "flex",
                      resize: "none",
                      borderRadius: "8px",
                      width: "100%",
                      padding: "10px",
                    }}
                    {...form.getInputProps("subject")}
                    withAsterisk
                  />
                </div>
              </Center>

              <div style={{ width: "100%", marginTop: "12px" }}>
                <label style={{ color: "#FFFFFF" }} htmlFor="content">
                  content
                </label>
                <textarea
                  size="xl"
                  id="content"
                  multiline
                  radius="md"
                  style={{
                    backgroundColor: "#283039",
                    color: "#FFFFFF",
                    border: "none",
                    display: "flex",
                    resize: "none",
                    borderRadius: "8px",
                    width: "100%",
                    height: "20vh",
                    padding: "10px",
                  }}
                  {...form.getInputProps("content")}
                />
              </div>

              <Button
                mt={8}
                size={"lg"}
                type="submit"
                radius="md"
                loading={sendMessageLoading}
                style={{ backgroundColor: "#02af74" }}
              >
                send
              </Button>
            </form>
          </div>
        </Grid.Col>

        <Grid.Col
          className={classes.imageContainer}
          xs={12}
          sm={6}
          md={5}
          lg={4}
          style={{ textAlign: "center", margin: "auto" }}
        >
          <Image
            maw={400}
            mx="auto"
            radius="md"
            src={conntactUs1}
            alt="Random image"
          />
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default ConntactUsSection;
