import { useState, useEffect } from "react";
import {
  Pagination,
  Avatar,
  Title,
  Group,
  Badge,
  Divider,
  Grid,
  Button,
} from "@mantine/core";
import { Link, useParams, useNavigate } from "react-router-dom";
import { BiLocationPlus, BiChevronsRight } from "react-icons/bi";
import useFetchHook from "../../useFetchHook/useFetchHook.js";
import BidsPageLoading from "../../components/LoadingComponents/BidsPageLoading";
import { Helmet } from "react-helmet-async";

function BidsPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, loading, error, refetchData } = useFetchHook(
    `api/show/bids/1?page=${id}`
  );
  const [bidssData, setBidssData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!loading && data.length !== 0) {
      setBidssData(data?.bids?.data || []);
      //getCategoresAndGovernmentData();
    }
  }, [data, id]);
  return (
    <div style={{ background: "#283039", padding: "40px", minHeight: "92vh" }}>
      <Helmet>
        <title>HireIn Bids and Tenders</title>
        <link rel="canonical" href="/bidspage/1" />
        <meta
          name="description"
          content="Explore the latest NGO tenders in Iraq and KRI on HireIn's NGO Tenders Portal. Find opportunities for your organization's growth."
        />
        <meta property="og:title" content="HireIn Bids and Tenders" />
        <meta
          property="og:description"
          content="Explore the latest NGO tenders in Iraq and KRI on HireIn's NGO Tenders Portal. Find opportunities for your organization's growth."
        />
      </Helmet>

      <div>
        <Title
          align="center"
          style={{ color: "#ffffff", fontSize: "3.5rem" }}
          order={1}
        >
          <span style={{ color: "#02af74" }}>HireIn</span> Bids and Tenders
        </Title>
        <Divider color="gray" variant="dotted" />
      </div>

      {loading || error ? (
        <div>
          <BidsPageLoading />
          <BidsPageLoading />
          <BidsPageLoading />
          <BidsPageLoading />
          <BidsPageLoading />
        </div>
      ) : (bidssData || []).length === 0 ? (
        <div>
          <Title
            align="center"
            style={{ color: "#ffffff", fontSize: "3.5rem", marginTop: "20vh" }}
            order={1}
          >
            No records found 😔
            {/* <span style={{ color: "#02af74" }}>HireIn</span> Bids and Tenders */}
          </Title>
        </div>
      ) : (
        bidssData.map((item, index) => (
          <div
            key={index}
            style={{
              background: "#283039",
              border: "2px solid #445c52",
              borderRadius: "15px",
              marginTop: "20px",
              padding: "10px",
            }}
          >
            <Group position="apart">
              <Title style={{ color: "#ffffff" }} order={2}>
                {item.bids_title}
              </Title>

              <Title style={{ color: "#02af74" }} order={2}>
                {item.ago_time}
              </Title>
            </Group>

            <Divider my="sm" variant="dashed" />

            <Grid>
              <Grid.Col xs={12} sm={6} md={6} lg={6}>
                <Group>
                  <Avatar size="lg" radius="md" src={item.organization_img} />

                  <div style={{ marginLeft: "15px", marginRight: "20px" }}>
                    <Title style={{ color: "#02af74" }} order={3}>
                      {item.organization_name}
                    </Title>
                    <Title style={{ color: "#adb5bd" }} order={4}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Iraq, Erbil
                        <BiLocationPlus
                          size="1.5rem"
                          color="#02af74"
                          style={{ marginLeft: "0.5rem" }}
                        />
                      </div>
                    </Title>
                  </div>
                </Group>
              </Grid.Col>

              <Grid.Col xs={12} sm={3} md={3} lg={3}>
                <Group>
                  <Badge
                    style={{ background: "#01462e", color: "#ffffff" }}
                    radius="sm"
                  >
                    {item.start_publishing}
                  </Badge>
                  <Badge
                    style={{ background: "#635221", color: "#ffffff" }}
                    radius="sm"
                  >
                    {item.stop_publishing}
                  </Badge>
                </Group>
              </Grid.Col>

              <Grid.Col xs={12} sm={3} md={3} lg={3}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Link to={`/bidDetailsPage/${item.bids_id}`}>
                    <Button
                      size={"lg"}
                      rightIcon={<BiChevronsRight />}
                      style={{ backgroundColor: "#02af74" }}
                    >
                      More Details
                    </Button>
                  </Link>
                </div>
              </Grid.Col>
            </Grid>
          </div>
        ))
      )}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {data.last_page > 1 ? (
          <Pagination
            mt={12}
            defaultValue={Number(id)}
            total={data.last_page}
            color="teal"
            onChange={(e) => {
              window.scrollTo(0, 0);
              navigate(`/BidsPage/${e}`);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default BidsPage;
