import "./App.css";
import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Header } from "./components/Header/Header";
import SharedContext from "./SharedContext";
//pages
import HomePage from "./pages/HomePage/HomePage";
import JobsPage from "./pages/JobsPage/JobsPage";
import BidsPage from "./pages/BidsPage/BidsPage";
import JobDetailsPage from "./pages/JobDetailsPage/JobDetailsPage";
import BidDetailsPage from "./pages/BidDetailsPage/BidDetailsPage";
import Page404Error from "./pages/Page404Error/Page404Error";

import "flowbite/dist/flowbite.css";

function App() {
  //const apiUrl = "http://192.168.171.162:8000/";
  const apiUrl = "https://server.hirein.mosulcode.com/";
  localStorage.setItem("apiUrl", apiUrl);

  const tabs = [
    { tabName: "Home", link: "/" },
    { tabName: "Jobs", link: "/Jobs/1?government=1&category=1" },
    { tabName: "Bids & Tenders", link: "/bidspage/1" },
  ];

  // Sample user data
  const [userData, setUserData] = useState([]);
  return (
    <div>
      <SharedContext.Provider value={{ setUserData, userData }}>
        <div>
          <header style={{ backgroundColor: "#2d3640" }}>
            <Header tabs={tabs} />
          </header>

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/Jobs/:id" element={<JobsPage />} />
            <Route path="/JobDetailsPage/:id" element={<JobDetailsPage />} />
            <Route path="/BidsPage/:id" element={<BidsPage />} />
            <Route path="/BidDetailsPage/:id" element={<BidDetailsPage />} />
            <Route path="*" element={<Page404Error />} />
          </Routes>
        </div>
      </SharedContext.Provider>
    </div>
  );
}

export default App;
